import React from 'react'
import { graphql, navigate, withPrefix } from 'gatsby'
import { getCoordinatesFromGeoJSONString } from '../util/jsonUtil'
import { PlacesMap } from '@cpt/ui'
import Layout from '../components/Layout'
import { FaInfoCircle } from 'react-icons/fa'
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Icon,
} from '@chakra-ui/react'

import Hero from '../components/Hero'

const isBrowser = typeof window !== 'undefined'

const getPersistedTeaserState = () => {
  if (isBrowser) {
    return localStorage.getItem(LOCAL_STORAGE_KEY) !== '0'
  } else {
    return true
  }
}

const LOCAL_STORAGE_KEY = 'INTRO'
export function Index({ data }: { data: GatsbyTypes.IndexPageQuery }) {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(() =>
    getPersistedTeaserState()
  )

  const showTeaser = (show: boolean) => {
    if (isBrowser) {
      localStorage.setItem(LOCAL_STORAGE_KEY, show ? '1' : '0')
    }

    setIsModalOpen(show)
  }

  return (
    <Layout>
      <>
        <Box
          bg="whiteAlpha.500"
          position="absolute"
          right="5"
          top="101"
          zIndex={2}
        >
          <Button
            colorScheme="blue"
            shadow="xl"
            onClick={() => showTeaser(true)}
          >
            <FaInfoCircle />
          </Button>
        </Box>

        <Box h="100%" zIndex="1" pos="relative">
          <PlacesMap
            clickHandler={(url) => navigate(url)}
            places={data.places.edges.map((p) => ({
              title: p.node.frontmatter.title,
              description: p.node.frontmatter.subTitle,
              coordinates: getCoordinatesFromGeoJSONString(
                p.node.frontmatter.places.location
              ).coordinates,
              url: `${withPrefix('place')}${p.node.fields.slug}`,
            }))}
          />
        </Box>
        <Modal
          size="xxl"
          isOpen={isModalOpen}
          onClose={() => showTeaser(false)}
          motionPreset="scale"
          isCentered
        >
          <ModalOverlay />
          <ModalContent maxWidth="800px">
            <ModalCloseButton />
            <ModalBody p="5">
              <Hero onHeroCta={() => showTeaser(false)} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexPage {
    places: allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "place" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            subTitle
            places {
              location
            }
          }
        }
      }
    }
  }
`
