import * as React from 'react'
import { Heading } from '@chakra-ui/react'
import ReactAudioPlayer from 'react-audio-player'

/* eslint-disable-next-line */
export interface AudioPlayerProps {
  headline: string
  url: string
  setPlayingState?: (play: boolean) => void
  play: boolean
}

export function AudioPlayer(props: AudioPlayerProps) {
  const { headline, url, setPlayingState, play } = props
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const setIsPlaying = setPlayingState ? setPlayingState : () => {}
  const ref = React.useRef<ReactAudioPlayer>()
  const $audio = ref.current ? ref.current.audioEl.current : null

  React.useEffect(() => {
    if ($audio) {
      if ($audio.paused && play) {
        $audio.play()
      }

      if (!$audio.paused && !play) {
        $audio.pause()
      }
    }
  }, [$audio, play])

  return (
    <>
      <Heading as="h3" size="sm" mb="4">
        {headline}
      </Heading>
      <ReactAudioPlayer
        src={url}
        autoPlay={false}
        controls={true}
        ref={ref}
        onPlay={() => {
          setIsPlaying(true)
        }}
        onPause={() => {
          setIsPlaying(false)
        }}
        onEnded={() => {
          setIsPlaying(false)
        }}
      />
    </>
  )
}

export default AudioPlayer
