import * as React from 'react'
import { Heading, Text, Box, Button, Fade } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

/* eslint-disable-next-line */
export interface HeroProps {
  onHeroCta: () => void
}

export function Hero(props: HeroProps) {
  return (
    <>
      <Heading
        as="h1"
        fontWeight="extrabold"
        letterSpacing="-1px"
        mb="3"
        fontSize={['2xl', '2xl', '3xl']}
      >
        Common Places, Common Times
      </Heading>
      <Box display="flex" width="xxl">
        <Box width={['100%', '100%', '60%']}>
          <Text>
            Erkundet auf der interaktiven Karte „Common Places, Common Times“
            Orte, Ereignisse und Personen aus der deutsch-jüdischen Geschichte.
          </Text>
          <Text>
            Jeder Karteneintrag besteht aus einem{' '}
            <strong>Informationstext</strong>, einem <strong>Interview</strong>{' '}
            mit Wissenschaftler*innen oder Zeitzeug*innen, das etwa 20 Minuten
            dauert, einem <strong>Quiz</strong> und{' '}
            <strong>Diskussionsfragen</strong>.
          </Text>
          <Text>
            Wenn ihr euch alleine oder mit anderen zusammen eingehend mit einem
            Karteneintrag beschäftigt, dauert das etwa 45 Minuten.
          </Text>
          <Button onClick={() => props.onHeroCta()} colorScheme="blue">
            Jetzt erkunden
          </Button>
        </Box>
        <Box
          display={['none', 'none', 'block']}
          width="40%"
          paddingLeft={'10px'}
          minHeight="380px"
          onClick={() => props.onHeroCta()}
          cursor="pointer"
        >
          <StaticImage
            src="./../images/Portrait_Nanette_Kaula.jpg"
            alt="Nanette Kaula"
            placeholder="blurred"
            layout="fixed"
            width={160}
            style={{
              boxShadow: '1px 1px 10px black',
              borderRadius: '2px',
              position: 'absolute',
              top: '25%',
              zIndex: 3,
            }}
          />
          <StaticImage
            src="./../images/Palais-Bernheimer.jpg"
            alt="Pailais Famile Bernheimer"
            placeholder="blurred"
            layout="fixed"
            width={180}
            style={{
              boxShadow: '1px 1px 10px black',
              borderRadius: '2px',
              position: 'absolute',
              right: 20,
            }}
          />

          <StaticImage
            src="./../images/Hechingen_Landesmuseum-Hohenzollern.jpg"
            alt="Landesmuseum Hechingen"
            placeholder="blurred"
            layout="fixed"
            width={220}
            style={{
              boxShadow: '1px 1px 10px black',
              borderRadius: '2px',
              position: 'absolute',
              right: 25,
              bottom: 20,
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default Hero
