import * as React from 'react';
import { Box, Heading, Text } from '@chakra-ui/layout';

/* eslint-disable-next-line */
export interface UiProps {}

export function Ui(props: UiProps) {
  return (
    <Box bg="test.good" color="white" p="4" rounded="5">
      <Heading>Welcome to UI!!</Heading>
      <Text color="gray.100">
        This component should show chakra ui in effect
      </Text>
    </Box>
  );
}

export default Ui;
