import { Box, Container, Heading } from '@chakra-ui/react'
import * as React from 'react'

import { Layout } from '../components/Layout'
/* eslint-disable-next-line */
export interface DatenschutzProps {}

export function Datenschutz(props: DatenschutzProps) {
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box
          className="static-page"
          background="white"
          p="5"
          shadow="lg"
          rounded="sm"
          mb="50px"
        >
          <Heading>Datenschutzerklärung</Heading>

          <p>
            Allgemeine Datenschutzerklärung für die Internetseiten der
            Ludwig-Maximilians-Universität München (im Folgenden „LMU“)
          </p>

          <p>
            <strong>
              I. Kontaktinformationen im Zusammenhang mit dem Internetauftritt
              der LMU
            </strong>
          </p>

          <p>
            <br />
            <strong>
              I.1. Angaben zum Verantwortlichen für den Datenschutz an der LMU
            </strong>
          </p>

          <p>
            <br />
            Für die Internetseiten der LMU ist der Verantwortliche im Sinne der
            Datenschutz-Grundverordnung (DSGVO) und anderer nationaler
            Datenschutzgesetze sowie sonstiger datenschutzrechtlicher
            Bestimmungen die LMU; sie wird durch ihren Präsidenten gesetzlich
            vertreten. Die Kontaktinformationen erhalten Sie im&nbsp;
            <a
              href="https://www.uni-muenchen.de/funktionen/impressum/index.html"
              title="Impressum &amp; Disclaimer"
            >
              Impressum
            </a>
            .
          </p>

          <p>
            Für die angebotenen Inhalte auf den Internetseiten der LMU sind die
            jeweiligen Einrichtungen der LMU verantwortlich. Für Fragen im
            Zusammenhang mit einer betreffenden Internetseite der LMU wenden Sie
            sich bitte an den jeweiligen verantwortlichen Ansprechpartner oder
            die jeweilige verantwortliche Ansprechpartnerin, wie er oder sie im
            Impressum auf der jeweiligen Internetseite genannt ist.
          </p>

          <p>
            <strong>
              I.2 Name und Anschrift des behördlichen Datenschutzbeauftragten
              der LMU
            </strong>
          </p>

          <p>
            Die Kontaktdaten des behördlichen Datenschutzbeauftragten der LMU
            finden Sie im Internet auf der Internetseite der LMU unter&nbsp;
            <a href="http://www.lmu.de/datenschutz">www.lmu.de/datenschutz</a>.
          </p>

          <p>
            Der behördliche Datenschutzbeauftragte steht für Fragen zum
            Datenschutz an der LMU zur Verfügung. Bitte nutzen Sie hierfür das
            Kontaktformular auf der Internetseite des behördlichen
            Datenschutzbeauftragten der LMU unter&nbsp;
            <a
              href="https://www.uni-muenchen.de/einrichtungen/orga_lmu/beauftragte/dschutz/datenschutzkontaktformular/index.html"
              target="_blank"
              title="Kontakt zum behördlichen Datenschutzbeauftragten"
              rel="noreferrer"
            >
              https://www.uni-muenchen.de/einrichtungen/orga_lmu/beauftragte/dschutz/Datenschutzkontaktformular.html
            </a>
            . Bitte nutzen Sie dies auch für die Meldung von etwaigen
            Datenschutzvorfällen, die Ihnen aufgrund der Nutzung der
            Internetseiten der LMU bekannt werden.
          </p>

          <p>
            <strong>
              II. Allgemeine Informationen zur Datenverarbeitung auf den
              Internetseiten der LMU
            </strong>
          </p>

          <p>
            <br />
            <strong>II.1 Anwendungsbereich der Datenschutzerklärung</strong>
          </p>

          <p>
            Diese Datenschutzerklärung gilt für die Verarbeitung von
            personenbezogenen Daten im Zusammenhang mit dem Internetauftritt der
            LMU.
          </p>

          <ul>
            <li>
              Gemäß Art. 4 Ziff. 1 DSGVO sind „personenbezogene Daten“ alle
              Informationen, die sich auf eine identifizierte oder
              identifizierbare natürliche Person beziehen; als identifizierbar
              wird eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              oder zu einem oder mehreren besonderen Merkmalen identifiziert
              werden kann, die Ausdruck der physischen, physiologischen,
              genetischen, psychischen, wirtschaftlichen, kulturellen oder
              sozialen Identität dieser natürlichen Person sind.
            </li>
            <li>
              Gemäß Art. 4 Ziff. 2 DSGVO meint „Verarbeitung“ jeden mit oder
              ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder
              jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
              Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen,
              die Speicherung, die Anpassung oder Veränderung, das Auslesen, das
              Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
              Verbreitung oder eine andere Form der Bereitstellung, den Abgleich
              oder die Verknüpfung, die Einschränkung, das Löschen oder die
              Vernichtung.
            </li>
          </ul>

          <p>
            <strong>
              II.2 Zwecke und Rechtsgrundlagen für die Verarbeitung
              personenbezogener Daten
            </strong>
          </p>

          <p>
            Wir bieten gemäß Art. 2 Abs. 6 BayHSchG, Art. 4 Abs. 1 Sätze 1 und 2
            BayEGovG auf unseren Webseiten unsere Dienste und
            Verwaltungsleistungen sowie Informationen für die Öffentlichkeit
            über unsere Tätigkeit an.
          </p>

          <p>
            Personenbezogene Daten werden auf den Internetseiten der LMU nur
            verarbeitet, soweit dies zur Bereitstellung einer funktionsfähigen
            Internetseite, für die Darstellung der jeweiligen Inhalte oder der
            Erbringung von bestimmten Leistungen bzw. Angeboten erforderlich
            ist. Die Verarbeitung personenbezogener Daten erfolgt entweder
            aufgrund einer gesetzlichen Grundlage oder einer nutzerbezogenen
            Einwilligung. Soweit die Verarbeitung von personenbezogenen Daten
            auf einer Einwilligung basiert, erfolgt dies aufgrund von Art. 6
            Abs. 1 lit. a DSGVO. Bei der Verarbeitung von personenbezogenen
            Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei der
            Nutzer ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
            Rechtsgrundlage. Soweit eine Verarbeitung personenbezogener Daten
            zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der
            die LMU unterliegt, gilt Art. 6 Abs. 1 lit. c DSGVO als
            Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der
            betroffenen Person oder einer anderen natürlichen Person eine
            Verarbeitung personenbezogener Daten erforderlich machen, dient Art.
            6 Abs. 1 lit. d DSGVO als Rechtsgrundlage. Die Verarbeitung kann
            auch zur Erfüllung einer Aufgabe, die der LMU übertragen ist und die
            im öffentlichen Interesse liegt, erforderlich sein (Art. 6 Abs. 1
            lit. e, Abs. 3 lit. b DSGVO i.V.m. Art. 4 Abs. 1 BayDSG). Weitere
            Rechtsgrundlagen können sich auch aus spezialgesetzlichen oder
            sonstigen gesetzlichen Regelungen ergeben, auf die im jeweiligen
            Einzelfall hingewiesen wird.
          </p>

          <p>
            Cookies, Protokolldateien und Webanalyse-Tools verwenden wir zur
            Erstellung von Geschäftsstatistiken, zur Durchführung von
            Organisationsuntersuchungen, zur Prüfung oder Wartung unseres
            Webdienstes und zur Gewährleistung der Netz- und
            Informationssicherheit gemäß Art. 6 Abs. 1 BayDSG, § 13 Abs. 7 TMG,
            Art. 11 Abs. 1 BayEGovG. Soweit und sobald der Verarbeitungszweck
            nicht beeinträchtigt wird, anonymisieren oder pseudonymisieren wir
            personenbezogene Daten.
          </p>

          <p>
            <strong>II.3 Datenlöschung und Speicherdauer</strong>
          </p>

          <p>
            Die personenbezogenen Daten von Nutzern der Internetseiten der LMU
            werden gelöscht oder anonymisiert, sobald und soweit der jeweilige
            Zweck der Speicherung entfällt und keine Archivierungspflicht
            besteht. Eine Löschung oder Sperrung der Daten erfolgt auch dann,
            wenn eine Speicherfrist, wie sie durch den europäischen oder
            nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen
            oder sonstigen Vorschriften, denen die LMU unterliegt, vorgesehen
            wurde, abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren
            Speicherung der Daten für einen Vertragsschluss oder eine
            Vertragserfüllung besteht. Sofern dies in den genannten Vorschriften
            vorgesehen ist, kann auch darüber hinaus eine Speicherung erfolgen.
          </p>

          <p>
            <strong>II.4 Datensicherheit</strong>
          </p>

          <p>
            Um die Sicherheit Ihrer Daten angemessen und umfassend bei der
            Verarbeitung und insbesondere der Übertragung zu schützen, verwenden
            wir, soweit erforderlich und orientiert am aktuellen Stand der
            Technik, entsprechende Verschlüsselungsverfahren (z. B. SSL/TLS) und
            gesicherte technische Systeme.
          </p>

          <p>
            <strong>
              III. Spezifische Informationen zur Datenverarbeitung auf den
              Internetseiten der LMU
            </strong>
          </p>

          <p>
            Bei jedem Aufruf einer Internetseite der LMU erfasst die LMU
            automatisiert Daten und Informationen vom Computersystem des jeweils
            aufrufenden Rechners. Zudem verarbeiten wir Ihre personenbezogenen
            Daten, soweit Sie diese über die Internetseiten der LMU angeben. Bei
            der Verarbeitung Ihrer personenbezogenen Daten berücksichtigen wir
            insbesondere die datenschutzrechtlichen Prinzipien der
            Rechtmäßigkeit, Verarbeitung nach Treu und Glauben, Transparenz,
            Zweckbindung, Datenminimierung, Richtigkeit, Speicherbegrenzung,
            Integrität und Vertraulichkeit.
          </p>

          <p>
            <strong>
              III.1 Verwendung von aktiven Komponenten und Cookies
            </strong>
          </p>

          <p>
            Im Informationsangebot der LMU werden JavaScript-Anwendungen
            verwendet, hauptsächlich für navigatorische Elemente, z.B.
            feldabhängige Sichtbarkeiten in Kontaktformularen. Dabei werden
            keine personenbezogenen Daten gespeichert. Die Verwendung dieser
            Funktionalitäten kann durch Einstellung Ihres Browserprogramms
            ausgeschaltet werden. Durch die Abschaltung kann die Darstellung
            einiger Elemente, z.B. Kontaktformulare, Videoplayer oder Akkordeon,
            beeinträchtigt sein.
          </p>

          <p>
            Teilweise werden im Internet-Auftritt der LMU Cookies gesetzt, z.B.
            für die Identifikation von Benutzer-Sitzungen, auch um die
            Internetseiten nutzerfreundlich zu gestalten. Bei Cookies handelt es
            sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser
            auf dem Computersystem des Nutzers gespeichert werden. Ruft ein
            Nutzer eine Internetseite auf, so kann ein Cookie auf dem
            Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält
            eine charakteristische Zeichenfolge, die eine eindeutige
            Identifizierung des Browsers beim erneuten Aufrufen der
            Internetseite ermöglicht.
          </p>

          <p>
            In den Cookies der öffentlichen Angebote wird ausschließlich eine
            Session-ID zur Identifikation der Benutzer-Sitzung gespeichert
            (Session-Cookie). Diese Session-Cookies werden nach Ende Ihres
            Besuchs beim Schließen ihres Browsers automatisch gelöscht.
          </p>

          <p>
            Verwendete Cookies werden auf Ihrem Rechner gespeichert und von
            diesem an die Internetseite der LMU übermittelt. Daher haben Sie als
            Nutzer die volle Kontrolle über die Verwendung von Cookies. Durch
            eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie
            die Übertragung von Cookies deaktivieren oder einschränken. Bereits
            gespeicherte Cookies können jederzeit gelöscht werden. Dies kann
            auch automatisiert erfolgen.
          </p>

          <p>
            Die Speicherung dieser Cookies kann durch die Einstellung im
            benutzen Internetbrowser jederzeit ausgeschaltet werden, dies kann
            auch automatisiert erfolgen.
          </p>

          <p>
            Einige Elemente unserer Internetseiten erfordern es allerdings, dass
            der aufrufende Internetbrowser auch nach einem Seitenwechsel
            identifiziert werden kann. Werden Cookies für die Internetseiten der
            LMU deaktiviert, können womöglich nicht mehr alle Funktionen der
            Internetseiten der LMU genutzt werden.
          </p>

          <p>
            Folgende Dienste sind nur nutzbar, wenn die Speicherung von Cookies
            erfolgt:
          </p>

          <ul>
            <li>Web-Formulare</li>
            <li>
              Der Veranstaltungskalender&nbsp;
              <a
                href="https://www.uni-muenchen.de/aktuelles/veranstaltungen/index.html"
                target="_blank"
                title="Veranstaltungen"
                rel="noreferrer"
              >
                https://ww.lmu.de/aktuelles/veranstaltungen
              </a>
            </li>
            <li>
              Alle über den zentralen Authentifizierungsmechanismus gesicherten
              Angebote
            </li>
          </ul>

          <p>
            Die Übermittlung von Flash-Cookies lässt sich nicht über die
            Einstellungen des Internetbrowsers, jedoch durch Änderungen der
            Einstellung des Flash Players unterbinden.
          </p>

          <p>
            <strong>III.2 Protokollierung und Erstellung von Logfiles</strong>
          </p>

          <p>
            Bei der bloß informatorischen Nutzung der Website, also wenn Sie
            sich nicht registrieren oder uns anderweitig Informationen
            übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr
            Browser an unseren Server übermittelt. Wenn Sie unsere Website
            betrachten möchten, erheben wir die folgenden Daten, die für uns
            technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und
            die Stabilität und Sicherheit zu gewährleisten. Rechtsgrundlage
            dafür ist Art. 6 Abs. 1 f DSGVO:
          </p>
          <p>
            Die Access-Logs der Webserver protokollieren, welche Seitenaufrufe
            zu welchem Zeitpunkt stattgefunden haben. Sie beinhalten folgende
            Daten: IP, Verzeichnisschutzbenutzer, Datum, Uhrzeit, aufgerufene
            Seiten, Protokolle, Statuscode, Datenmenge, Referer, User Agent,
            aufgerufener Hostname.
          </p>
          <p>
            Die IP-Adressen werden dabei anonymisiert gespeichert. Hierzu werden
            die letzten drei Ziffern entfernt, d.h. aus 127.0.0.1 wird
            127.0.0.*. IPv6-Adressen werden ebenfalls anonymisiert. Die
            anonymisierten IP-Adressen werden für 60 Tage aufbewahrt. Angaben
            zum verwendeten Verzeichnisschutzbenutzer werden nach einem Tag
            anonymisiert.
          </p>
          <p>
            Error-Logs, welche fehlerhafte Seitenaufrufe protokollieren, werden
            nach sieben Tagen gelöscht. Diese beinhalten neben den
            Fehlermeldungen die zugreifende IP-Adresse und je nach Fehler die
            aufgerufene Webseite.
          </p>

          <p>
            <strong>III.9 Einsatz von Leaflet/Carto</strong>
          </p>

          <p>
            Die eingebundenen Karten verwenden OpenStreetMap (OSM) Daten in
            Verbindung mit CARTO als Tile Layer (Kartenkacheln). Beim Anzeigen
            der Karten wird eine Anfrage an den Carto-Server gestellt. Bei
            dieser Anfrage werden ebenfalls Daten gespeichert. Die entsprechende
            Rechtsgrundlage bildet das berechtigte Interesse gemäß Art. 6 Abs.1
            S.1 f DSVGO, da die interaktiven Karten basierend auf OSM und CARTO
            das Kernstück der Webanwendung darstellen. Die Datenschutzerklärung
            von CARTO ist unter Datenschutz CARTO16 in englischer Sprache zu
            finden. Die Datenschutzerklärung von OpenStreetMap ist unter
            Datenschutz OpenStreetMap17 in englischer Sprache zu finden.
          </p>
          <p>
            CARTO, Attention: Legal, 307 Fifth Avenue, Floor 9 New York, NY
            10016, United States of America
          </p>

          <p>
            <strong>
              IV. Ihre datenschutzrechtlichen Rechte als Betroffener
            </strong>
          </p>

          <p>
            Im Rahmen der Internetauftritte der LMU werden personenbezogene
            Daten im o.g. Umfang verarbeitet. Insoweit sind Sie Betroffener
            i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber der LMU
            zu:
          </p>

          <p>
            <strong>IV.1 Auskunftsrecht</strong>
          </p>

          <p>
            <br />
            Sie können von der LMU eine Bestätigung darüber verlangen, ob
            personenbezogene Daten, die Sie betreffen, von uns verarbeitet
            werden.
          </p>

          <p>
            Liegt eine solche Verarbeitung vor, können Sie von der LMU über
            folgende Informationen Auskunft verlangen:
          </p>

          <ul>
            <li>
              die Zwecke, zu denen die personenbezogenen Daten verarbeitet
              werden;
            </li>
            <li>
              die Kategorien von personenbezogenen Daten, welche verarbeitet
              werden;
            </li>
            <li>
              die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
              die Sie betreffenden personenbezogenen Daten offengelegt wurden
              oder noch offengelegt werden;
            </li>
            <li>
              die geplante Dauer der Speicherung der Sie betreffenden
              personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
              möglich sind, Kriterien für die Festlegung der Speicherdauer;
            </li>
            <li>
              das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
              betreffenden personenbezogenen Daten, eines Rechts auf
              Einschränkung der Verarbeitung durch den hierfür Verantwortlichen
              bzw. die hierfür Verantwortliche oder eines Widerspruchsrechts
              gegen diese Verarbeitung;
            </li>
            <li>
              das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
              die für die LMU unmittelbar zuständige
              Datenschutz-Aufsichtsbehörde ist der Bayerische Landesbeauftragte
              für den Datenschutz (
              <a
                href="https://www.datenschutz-bayern.de/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.datenschutz-bayern.de
              </a>
              &nbsp;(externer Link));
            </li>
            <li>
              alle verfügbaren Informationen über die Herkunft der Daten, wenn
              die personenbezogenen Daten nicht bei Ihnen erhoben werden;
            </li>
            <li>
              das Bestehen einer automatisierten Entscheidungsfindung
              einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
              zumindest in diesen Fällen – aussagekräftige Informationen über
              die involvierte Logik sowie die Tragweite und die angestrebten
              Auswirkungen einer derartigen Verarbeitung für die betroffene
              Person.
            </li>
            <li>
              Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die
              Sie betreffenden personenbezogenen Daten in ein Drittland oder an
              eine internationale Organisation übermittelt werden. In diesem
              Zusammenhang können Sie verlangen, über die geeigneten Garantien
              gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung
              unterrichtet zu werden.
            </li>
          </ul>

          <p>
            Das Auskunftsrecht unterliegt gesetzlichen Beschränkungen und gilt
            nicht absolut, sondern findet seine Grenze insbesondere in folgenden
            Fällen:
          </p>

          <ul>
            <li>
              Bei einer großen Menge von gespeicherten Informationen über die
              betroffene Person kann die LMU verlangen, dass präzisiert wird,
              auf welche Informationen oder Verarbeitungsvorgänge sich das
              Auskunftsersuchen konkret bezieht.
            </li>
            <li>
              Offenkundig unbegründete oder exzessive Anträge bzw. häufige
              Wiederholungen können zur Ablehnung oder zu einer
              Kostenerstattungspflicht führen.
            </li>
            <li>
              Die Auskunftserteilung darf die Rechte der LMU oder anderer
              Personen nicht beeinträchtigen (insofern sind Berufsgeheimnisse,
              Geschäftsgeheimnisse, Daten mit Bezug zu anderen Personen
              ausgenommen).
            </li>
            <li>
              Unter den in Art. 10 BayDSG genannten Voraussetzungen kann die
              Auskunft unterbleiben.
            </li>
            <li>
              Bei einer Datenverarbeitung zu wissenschaftlichen oder
              historischen Forschungszwecken und zu statistischen Zwecken kann
              Ihr Auskunftsrecht darüber hinaus insoweit beschränkt werden, als
              es voraussichtlich die Verwirklichung der Forschungs- oder
              Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und
              die Beschränkung für die Erfüllung der Forschungs- oder
              Statistikzwecke notwendig ist (Art. 25 BayDSG).
            </li>
          </ul>

          <p>
            <strong>IV.2 Recht auf Berichtigung</strong>
          </p>

          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
            gegenüber der LMU, sofern die verarbeiteten personenbezogenen Daten,
            die Sie betreffen, unrichtig oder unvollständig sind. Die LMU wird
            die Berichtigung unverzüglich vornehmen.
          </p>

          <p>
            Bei einer Datenverarbeitung zu wissenschaftlichen oder historischen
            Forschungszwecken und zu statistischen Zwecken kann Ihr Recht auf
            Berichtigung insoweit beschränkt werden, als es voraussichtlich die
            Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht
            oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung
            der Forschungs- oder Statistikzwecke notwendig ist (Art. 25 BayDSG).
          </p>

          <p>
            <strong>IV.3 Recht auf Einschränkung der Verarbeitung</strong>
          </p>

          <p>
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der
            Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
          </p>

          <ul>
            <li>
              wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
              Daten für eine Dauer bestreiten, die es der LMU ermöglicht, die
              Richtigkeit der personenbezogenen Daten zu überprüfen;
            </li>
            <li>
              die Verarbeitung unrechtmäßig ist und Sie die Löschung der
              personenbezogenen Daten ablehnen und stattdessen die Einschränkung
              der Nutzung der personenbezogenen Daten verlangen;
            </li>
            <li>
              die LMU die personenbezogenen Daten für die Zwecke der
              Verarbeitung nicht länger benötigt, Sie diese jedoch zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              benötigen, oder
            </li>
            <li>
              wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
              DSGVO eingelegt haben und noch nicht feststeht, ob die
              berechtigten Gründe der LMU Ihren schutzwürdigen Interessen
              überwiegen.
              <br />
              Wurde die Verarbeitung der Sie betreffenden personenbezogenen
              Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der Union oder
              eines Mitgliedstaats verarbeitet werden.
            </li>
          </ul>

          <p>
            Wurde die Einschränkung der Verarbeitung nach den o.g.
            Voraussetzungen eingeschränkt, werden Sie von der LMU unterrichtet
            bevor die Einschränkung aufgehoben wird.
          </p>

          <p>
            Bei einer Datenverarbeitung zu wissenschaftlichen oder historischen
            Forschungszwecken und zu statistischen Zwecken kann Ihr Recht auf
            Einschränkung der Verarbeitung insoweit beschränkt werden, als es
            voraussichtlich die Verwirklichung der Forschungs- oder
            Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und
            die Beschränkung für die Erfüllung der Forschungs- oder
            Statistikzwecke notwendig ist (Art. 25 BayDSG).
          </p>

          <p>
            <strong>IV.4 Recht auf Löschung</strong>
          </p>

          <p>
            <strong>a) Löschungspflicht</strong>
          </p>

          <p>
            Sie können von der LMU verlangen, dass die Sie betreffenden
            personenbezogenen Daten unverzüglich gelöscht werden. Die LMU ist
            verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der
            folgenden Gründe zutrifft:
          </p>

          <ul>
            <li>
              Die Sie betreffenden personenbezogenen Daten sind für die Zwecke,
              für die sie erhoben oder auf sonstige Weise verarbeitet wurden,
              nicht mehr notwendig, und die Verarbeitung zu anderen Zwecken ist
              unzulässig.
            </li>
            <li>
              Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
              gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte,
              und es fehlt an einer anderweitigen Rechtsgrundlage für die
              Verarbeitung.
            </li>
            <li>
              Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
              Verarbeitung ein und es liegen keine vorrangigen berechtigten
              Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs.
              2 DSGVO Widerspruch gegen die Verarbeitung ein.
            </li>
            <li>
              Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
              verarbeitet.
            </li>
            <li>
              Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
              Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht
              oder dem Recht der Mitgliedstaaten erforderlich, dem der oder die
              Verantwortliche unterliegt.
            </li>
            <li>
              Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
              angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs.
              1 DSGVO erhoben.
            </li>
          </ul>

          <p>
            <strong>b) Information an Dritte</strong>
          </p>

          <p>
            Sofern die LMU die Sie betreffenden personenbezogenen Daten
            öffentlich gemacht hat und sie gem. Art. 17 Abs. 1 DSGVO zu deren
            Löschung verpflichtet ist, so trifft sie unter Berücksichtigung der
            verfügbaren Technologie und der Implementierungskosten angemessene
            Maßnahmen, auch technischer Art, um die für die Datenverarbeitung
            Verantwortlichen, die die personenbezogenen Daten verarbeiten,
            darüber zu informieren, dass Sie als betroffene Person von ihnen die
            Löschung aller Links zu diesen personenbezogenen Daten oder von
            Kopien oder Replikationen dieser personenbezogenen Daten verlangt
            haben.
          </p>

          <p>
            <strong>c) Ausnahmen</strong>
          </p>

          <p>
            Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
            erforderlich ist
          </p>

          <ul>
            <li>
              zur Ausübung des Rechts auf freie Meinungsäußerung und
              Information;
            </li>
            <li>
              zur Erfüllung einer rechtlichen Verpflichtung, die die
              Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten,
              dem die LMU unterliegt, erfordert, oder zur Wahrnehmung einer
              Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
              öffentlicher Gewalt erfolgt, die der LMU übertragen wurde;
            </li>
            <li>
              aus Gründen des öffentlichen Interesses im Bereich der
              öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
              Art. 9 Abs. 3 DSGVO;
            </li>
            <li>
              für im öffentlichen Interesse liegende Archivzwecke,
              wissenschaftliche oder historische Forschungszwecke oder für
              statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das oben
              unter Buchst. a) genannte Recht voraussichtlich die Verwirklichung
              der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
              beeinträchtigt, oder
            </li>
            <li>
              zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen.
            </li>
          </ul>

          <p>
            <strong>IV.5 Recht auf Unterrichtung</strong>
          </p>

          <p>
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung gegenüber der LMU geltend gemacht, sind wir gemäß
            Art. 19 DSGVO verpflichtet, allen Empfängern, denen die Sie
            betreffenden personenbezogenen Daten offengelegt wurden, diese
            Berichtigung oder Löschung der Daten oder Einschränkung der
            Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
            unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
            <br />
            Ihnen steht gegenüber der LMU das Recht zu, über diese Empfänger
            unterrichtet zu werden.
          </p>

          <p>
            <strong>IV.6 Recht auf Datenübertragbarkeit</strong>
          </p>

          <p>
            Sie haben unter den Voraussetzungen des Art. 20 DSGVO das Recht, die
            Sie betreffenden personenbezogenen Daten, die Sie der LMU
            bereitgestellt haben, in einem strukturierten, gängigen und
            maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht,
            diese Daten einem oder einer anderen Verantwortlichen ohne
            Behinderung durch die LMU zu übermitteln, sofern
          </p>

          <ul>
            <li>
              die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
              DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem.
              Art. 6 Abs. 1 lit. b DSGVO beruht und
            </li>
            <li>
              die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
            </li>
          </ul>

          <p>
            In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken,
            dass die Sie betreffenden personenbezogenen Daten direkt von der LMU
            an einen anderen Verantwortlichen oder eine andere Verantwortliche
            übermittelt werden, soweit dies technisch machbar ist. Freiheiten
            und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt
            werden.
          </p>

          <p>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
            personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
            erforderlich ist, die im öffentlichen Interesse liegt oder in
            Ausübung öffentlicher Gewalt erfolgt, die der LMU übertragen wurde.
          </p>

          <p>
            <strong>IV.7 Widerspruchsrecht</strong>
          </p>

          <p>
            Sie haben&nbsp;unter den Voraussetzungen des Art. 21 DSGVO das
            Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
            jederzeit gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder
            f DSGVO erfolgt, Widerspruch einzulegen.
          </p>

          <p>
            Die LMU verarbeitet die Sie betreffenden personenbezogenen Daten
            nicht mehr, es sei denn, sie kann zwingende schutzwürdige Gründe für
            die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
            Freiheiten überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>

          <p>
            Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
            Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
            2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
            auszuüben, bei denen technische Spezifikationen verwendet werden.
          </p>

          <p>
            Bei einer Verarbeitung Sie betreffender personenbezogener Daten zu
            wissenschaftlichen oder historischen Forschungszwecken und zu
            statistischen Zwecken gem. Art. 89 Abs. 1 DSGVO haben Sie auch das
            Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
            dieser Datenverarbeitung zu widersprechen.
          </p>

          <p>
            Ihr Widerspruchsrecht kann insoweit beschränkt werden, als es
            voraussichtlich die Verwirklichung der Forschungs- oder
            Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und
            die Beschränkung für die Erfüllung der Forschungs- oder
            Statistikzwecke notwendig ist (Art. 25 BayDSG).
          </p>

          <p>
            <strong>
              IV.8 Recht auf Widerruf der datenschutzrechtlichen
              Einwilligungserklärung
            </strong>
          </p>

          <p>
            Sie haben das Recht, Ihre datenschutzrechtliche
            Einwilligungserklärung jederzeit mit Wirkung für die Zukunft zu
            widerrufen, wobei der Widerruf die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Datenverarbeitung nicht
            berührt (Art. 7 Abs. 3 DSGVO). Der Widerruf ist immer gegenüber der
            Stelle innerhalb der LMU zu erklären, die die Einwilligung eingeholt
            hat.
          </p>

          <p>
            <strong>
              IV.9 Recht auf Beschwerde bei einer Aufsichtsbehörde
            </strong>
          </p>

          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            einer Datenschutz-Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des
            Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass
            die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
            die DSGVO verstößt. Die für die LMU unmittelbar zuständige
            Datenschutz-Aufsichtsbehörde ist der Bayerische Landesbeauftragte
            für den Datenschutz (
            <a
              href="https://www.datenschutz-bayern.de/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.datenschutz-bayern.de&nbsp;
            </a>
            (externer Link)). Die Aufsichtsbehörde, bei der die Beschwerde
            eingereicht wurde, unterrichtet den Beschwerdeführer oder die
            Beschwerdeführerin über den Stand und die Ergebnisse der Beschwerde
            einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
            nach Art. 78 DSGVO.
          </p>

          <p>
            Sofern Sie meinen, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten gegen die DSGVO verstößt, bitten wir darum,
            sich zunächst an den für die Inhalte der jeweiligen Internetseite
            verantwortlichen Ansprechpartner oder die verantwortliche
            Ansprechpartnerin zu wenden, wie er oder sie im Impressum genannt
            ist, und/ oder an den behördlichen Datenschutzbeauftragten der LMU,
            da dies eine zeitnahe Prüfung und ggf. Abhilfe in Ihrem Sinne
            ermöglicht. Es ist unser Ziel und Anspruch, alle aufkommenden
            datenschutzrechtlichen Fragen unmittelbar zu klären und mögliche
            datenschutzrechtliche Probleme zu lösen.
          </p>

          <p>
            <strong>IV.10 IV.10 Geltendmachung der Rechte</strong>
          </p>

          <p>
            Bitte wenden Sie sich für den Fall, dass Sie von Ihren Rechten
            Gebrauch machen wollen an die jeweilige Dienststelle, wo die
            betreffende Datenverarbeitung erfolgt bzw. die auf der jeweiligen
            Internetseite angegeben ist. Sie haben auch die Möglichkeit, sich an
            den behördlichen Datenschutzbeauftragten zu wenden.
          </p>

          <p>
            <strong>
              V. Geltung der allgemeinen Datenschutzerklärung und Reichweite
              einer ergänzenden Datenschutzerklärung des oder der für den Inhalt
              der Internetseite Verantwortlichen
            </strong>
          </p>

          <p>
            Die allgemeine Datenschutzerklärung gilt für diejenigen
            Internetseiten der LMU, für die die LMU die Verantwortung trägt. Für
            die jeweilige Internetseite der LMU kann noch eine ergänzende
            Datenschutzerklärung gelten, soweit der oder die jeweils für den
            Inhalt der Internetseite Verantwortliche eine weitere Verarbeitung
            personenbezogener Daten vornimmt und hierüber informiert. Dies gilt
            insbesondere dann, wenn von einzelnen Bereichen spezifische Services
            angeboten werden. Die jeweils ergänzende Datenschutzinformation auf
            den jeweiligen Internetseiten der LMU ist Teil der allgemeinen
            Datenschutzerklärung der LMU.
          </p>

          <p>
            <strong>
              VI. Stand, Änderungen und Geltung der allgemeinen
              Datenschutzerklärung der LMU
            </strong>
          </p>

          <p>
            Diese allgemeine Datenschutzerklärung hat den Stand 12/2021. Wir
            behalten uns vor, diese Datenschutzerklärung regelmäßig zu
            aktualisieren, um den aktuellen rechtlichen Anforderungen und
            technischen Änderungen Rechnung zu tragen sowie um unsere
            Dienstleistungen und Angebote datenschutzkonform umzusetzen. Für
            Ihren Besuch einer LMU-Webseite gilt die jeweils aktuelle Fassung.
          </p>
        </Box>
      </Container>
    </Layout>
  )
}

export default Datenschutz
