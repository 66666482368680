import { Button } from '@chakra-ui/button'
import { Box, Heading, Link } from '@chakra-ui/layout'
import * as React from 'react'
/* eslint-disable-next-line */
export interface QuizProps {
  id: string
}

export function Quiz(props: QuizProps) {
  const [isOptIn, setIsOptIn] = React.useState(false)
  return (
    <Box mt="4">
      {!isOptIn && (
        <Box mb="5">
          <Button
            as={Link}
            variant="outline"
            colorScheme="blue"
            size="sm"
            href={`https://learningapps.org/watch?v=${props.id}`}
            target="_blank"
          >
            In neuem Fenster öffnen
          </Button>{' '}
          <Button
            size="sm"
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              const optIn = window.confirm(
                'Mit dem Aufruf des Quiz erklären Sie sich damit einverstanden, dass Ihre Daten an LearningApps.org übermittelt werden - siehe Datenschutzerklärung.'
              )
              if (optIn) {
                setIsOptIn(true)
              }
            }}
          >
            Eingebunden anzeigen
          </Button>
        </Box>
      )}
      {isOptIn && (
        <iframe
          loading="lazy"
          title="Learning App"
          src={`https://learningapps.org/watch?v=${props.id}`}
          style={{ border: 0, width: '100%', height: '60vh' }}
          allowFullScreen={true}
        ></iframe>
      )}
    </Box>
  )
}

export default Quiz
