import { Container, Box, Heading } from '@chakra-ui/layout'
import React from 'react'
import Layout from '../components/Layout'

/* eslint-disable-next-line */
export interface ImpressumProps {}

export function Impressum(props: ImpressumProps) {
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box
          className="static-page"
          background="white"
          p="5"
          shadow="lg"
          rounded="sm"
          mb="50px"
        >
          <h1>Impressum</h1>

          <h2>Verantwortliche für den Inhalt i.S.d. § 55 Abs. 2 RStV:</h2>
          <p>
            Ludwig-Maximilians-Universität München
            <br />
            Lehrstuhl für Jüdische Geschichte und Kultur
            <br />
            Julia Treindl
            <br />
          </p>

          <p>
            E-Mail:&nbsp;
            <a href="mailto:julia.treindl@lrz.uni-muenchen.de">
              julia.treindl@lrz.uni-muenchen.de
            </a>{' '}
          </p>

          <h2>Verantwortlich für die technische Umsetzung</h2>

          <p>
            <a href="https://digitalnoise.de/">digitalnoise GmbH</a>
          </p>

          <h2>Freistellungserklärung</h2>

          <p>
            Die Angaben wurden nach bestem Wissen erstellt, Fehler können jedoch
            nicht mit letzter Sicherheit ausgeschlossen werden. Rechtlich
            verbindlich sind ausschließlich die Festlegungen in den
            einschlägigen Rechtsgrundlagen (Gesetze, Verordnungen, Satzungen).
          </p>

          <p>
            Eine Gewähr für die jederzeitige Aktualität, Richtigkeit,
            Vollständigkeit und Verfügbarkeit der bereitgestellten Informationen
            können wir nicht übernehmen.
          </p>

          <p>
            Wir haften nicht für Schäden, die durch die Nutzung dieses
            Internetangebots entstehen. Dieser Haftungsausschluss gilt nicht,
            soweit die Vorschriften des § 839 BGB (Haftung bei
            Amtspflichtverletzung) einschlägig sind. Für etwaige Schäden, die
            beim Aufrufen oder Herunterladen von Daten durch Schadsoftware oder
            der Installation oder Nutzung von Software verursacht werden, wird
            nicht gehaftet.
          </p>

          <p>
            Der Haftungsausschluss gilt nicht für Informationen, die in den
            Anwendungsbereich der Verordnung (EU) 2016/679 des Europäischen
            Parlaments und des Rates vom 27. April 2016
            (Datenschutz-Grundverordnung) fallen. Für diese Informationen wird
            die Richtigkeit und Aktualität gewährleistet.
          </p>

          <h2>Disclaimer</h2>

          <p>
            Im Informationsangebot der LMU befinden sich Querverweise ("Links")
            zu anderen Internetangeboten der LMU oder externer Dritter. Die
            Internetseiten öffnen sich grundsätzlich in einem neuen Fenster
            automatisch.
          </p>

          <p>
            Durch den Querverweis vermittelt die LMU den Zugang zur Nutzung
            dieser Inhalte (§ 8 Telemediengesetz). Für diese "fremden" Inhalte
            ist sie nicht verantwortlich, da sie die Übermittlung der
            Information nicht veranlasst, den Adressaten der übermittelten
            Informationen nicht auswählt und die übermittelten Informationen
            auch nicht ausgewählt oder verändert hat.
          </p>

          <p>
            Auch eine automatische kurzzeitige Zwischenspeicherung dieser
            "fremden Informationen" erfolgt wegen der gewählten Aufruf- und
            Verlinkungsmethodik durch die LMU nicht, so dass sich auch dadurch
            keine Verantwortlichkeit der LMU für diese fremden Inhalte ergibt.
          </p>

          <p>
            Bei der erstmaligen Verknüpfung mit diesen Internetangeboten hat die
            LMU den fremden Inhalt jedoch daraufhin überprüft, ob durch ihn eine
            mögliche zivilrechtliche oder strafrechtliche Verantwortung
            ausgelöst wird. Wir erhalten aber keine automatischen Informationen
            über Veränderungen der fremden Internetangebote und können deren
            Inhalte auch nicht ständig auf Veränderungen überprüfen. Deshalb
            können wir auch keine Verantwortung für diese übernehmen. Für
            illegale, fehlerhafte oder unvollständige Inhalte und insbesondere
            für Schäden, die aus der Nutzung oder Nichtnutzung von Informationen
            Dritter entstehen, haftet allein der jeweilige Anbieter des fremden
            Internetangebotes. Wir bemühen uns jedoch, die eingebundenen
            Verlinkungen regelmäßig bezüglich der genannten Kriterien zu prüfen.
          </p>
        </Box>
      </Container>
    </Layout>
  )
}

export default Impressum
