import * as React from 'react'

import { Heading, List, ListItem, ListIcon } from '@chakra-ui/layout'
import { FiBookOpen } from 'react-icons/fi'

/* eslint-disable-next-line */
export interface ReferencesProps {
  headline: string
  references: string[]
}

export function References(props: ReferencesProps) {
  return (
    <>
      <Heading as="h3" size="sm" mt="8" mb="4">
        {props.headline}
      </Heading>
      <List spacing="3">
        {props.references.map((reference, key) => (
          <ListItem key={key}>
            <ListIcon as={FiBookOpen} color="gray.500" />
            {reference}
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default References
