type GeoJson = {
  coordinates: [number, number]
}
export const getCoordinatesFromGeoJSONString = (
  geoJsonString: string
): GeoJson | null => {
  try {
    const parsed: GeoJson = JSON.parse(geoJsonString)
    return parsed
  } catch (e) {
    return null
  }
}
