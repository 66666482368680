import * as React from 'react'
import {
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Heading,
} from '@chakra-ui/react'

/* eslint-disable-next-line */
export interface GlossaryProps {
  entries: {
    term: string
    explanation: string
  }[]
}

export function Glossary(props: GlossaryProps) {
  const { entries } = props

  return (
    <Box>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Begriff</Th>
            <Th>Erklärung</Th>
          </Tr>
        </Thead>
        <Tbody>
          {entries.map((entry, index) => (
            <Tr key={index}>
              <Td>{entry.term}</Td>
              <Td>{entry.explanation}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default Glossary
