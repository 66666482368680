import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  styles: {
    global: {
      u: {
        textDecoration: 'none',
      },
      'u > em': {
        background: '#e2f2ff',
        fontStyle: 'normal',
        color: 'black',
        padding: '2px 5px',
        textDecoration: 'none',
      },
      audio: {
        width: '100%',
      },
      '.static-page': {
        'margin-top': '3em',
        'max-width': '60em',
        ul: {
          'margin-bottom': '3em',
        },
        li: {
          'margin-left': '2em',
        },
        h1: {
          'font-size': '2em',
          'font-weight': 'bold',
        },
        h2: {
          'font-weight': 'bold',
          'margin-bottom': '0.875em',
        },
        a: {
          'text-decoration': 'underline',
        },
      },
      nav: {
        li: {
          display: 'inline-block',
          'margin-left': '12px',
        },
      },
      p: {
        marginBottom: '1em',
      },
      '.information-text h2': {
        'font-family': 'var(--chakra-fonts-heading)',
        'font-weight': 'var(--chakra-fontWeights-bold)',
        'font-size': 'var(--chakra-fontSizes-md)',
        'line-height': '1.2',
        'margin-bottom': 'var(--chakra-space-2)',
      },
    },
  },
  fontSizes: {
    xxs: '0.6rem',
  },
  colors: {
    test: {
      good: 'green',
      bad: 'red',
    },
  },
})

export default theme
