import * as React from 'react'
import { List, ListItem, ListIcon, Heading } from '@chakra-ui/layout'
import { FiImage } from 'react-icons/fi'

/* eslint-disable-next-line */
export interface PictureCreditsProps {
  headline: string
  credits: string[]
}

export function PictureCredits(props: PictureCreditsProps) {
  return (
    <>
      <Heading as="h3" size="sm" mt="8" mb="4">
        {props.headline}
      </Heading>
      <List spacing={2}>
        {props.credits.map((credit, key) => (
          <ListItem key={key}>
            <ListIcon as={FiImage} color="gray.500" />
            Abb. {key + 1}: {credit}
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default PictureCredits
