import { graphql, Link, withPrefix } from 'gatsby'
import { Helmet } from 'react-helmet'
import {
  AudioPlayer,
  Discussion,
  Glossary,
  PictureCredits,
  Quiz,
  References,
} from '@cpt/ui'
import { FiPlayCircle, FiPauseCircle } from 'react-icons/fi'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { GrMap } from 'react-icons/gr'

import { IoLocationOutline } from 'react-icons/io5'

import {
  Badge,
  Flex,
  Box,
  Container,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Divider,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { GatsbyImage, getImage, Placeholder } from 'gatsby-plugin-image'
import { unzipSync } from 'zlib'
import { Layout } from '../../components/Layout'

type PlacePageProps = {
  data: GatsbyTypes.PlacePageQuery
}

export default function Place({ data }: PlacePageProps) {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false)
  const [bigPictureIndex, setBigPictureIndex] = React.useState<number | null>(
    null
  )

  const place = {
    ...data.place.frontmatter,
    information: data.place.information,
  }

  const selfIndex = data.all.nodes.map((n) => n.id).indexOf(data.place.id)
  const previousPlace =
    data.all.edges[selfIndex].previous ??
    data.all.edges[data.all.edges.length - 2].next
  const nextPlace = data.all.edges[selfIndex].next ?? data.all.edges[1].previous
  const questions = place.discussion.map((d) => d.question.html)

  useEffect(() => {
    const allUs = document.querySelectorAll<HTMLElement>('u')
    for (let i = 0; i < allUs.length; i++) {
      const $e = allUs[i]
      $e.title = 'Mehr Informationen im Glossar'
      $e.style['cursor'] = 'help'
    }
  }, [])

  const AccordionToggle = (props) => (
    <h2>
      <AccordionButton
        bg="blue.500"
        color="white"
        _hover={{ bg: 'blue.300' }}
        _expanded={{ bg: 'blue.400' }}
        transition="all"
      >
        <Box
          flex="1"
          textAlign="left"
          textTransform="uppercase"
          letterSpacing="2px"
          fontWeight="bold"
        >
          {props.title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
  )

  const title = [
    data.place.frontmatter.title,
    data.place.frontmatter.subTitle,
  ].join(' | ')

  return (
    <Layout>
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta
            property="og:url"
            content={withPrefix(`/place/${data.place.fields.slug}`)}
          />
          <meta name="description" content={data.place.frontmatter.teaser} />
        </Helmet>
        <Box pt="25px" pb="50px">
          <Container maxW="container.lg" shadow="2xl" p="5">
            <Modal
              size="xl"
              isOpen={bigPictureIndex !== null}
              onClose={() => setBigPictureIndex(null)}
              motionPreset="scale"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalBody p="2">
                  {place.gallery[bigPictureIndex] && (
                    <GatsbyImage
                      image={getImage(
                        place.gallery[bigPictureIndex].photo.image.big
                          .gatsbyImageData
                      )}
                      alt={place.gallery[bigPictureIndex].photo.description}
                      onClick={() => setBigPictureIndex(null)}
                      style={{ cursor: 'zoom-out' }}
                    />
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>

            <Flex background="white" p="5" rounded="3">
              <Box w={['100%', '100%', '70%', '70%']}>
                <Box flex="1" flexDir="row">
                  <Icon
                    as={IoLocationOutline}
                    mr="1"
                    pos="relative"
                    top="3px"
                  />
                  <Text
                    as="span"
                    mr="2"
                    fontSize="xs"
                    textTransform="uppercase"
                    fontWeight="500"
                    color="gray.500"
                  >
                    {place.places.address}
                  </Text>
                  {place.places.title && (
                    <Text as="span" fontSize="xs" color="gray.500">
                      {place.places.title}
                    </Text>
                  )}
                </Box>
                <Badge mt="5" colorScheme="orange">
                  {place.author}
                </Badge>
                <Heading
                  as="h1"
                  textTransform="uppercase"
                  fontWeight="bold"
                  letterSpacing="1px"
                >
                  {place.title}
                </Heading>
                <Heading
                  as="h2"
                  size="md"
                  mb="4"
                  fontWeight="light"
                  color="gray.600"
                >
                  {place.subTitle}
                </Heading>
                <Text
                  as="p"
                  fontSize="md"
                  fontWeight="semibold"
                  color="gray.900"
                  mb="8"
                >
                  <p dangerouslySetInnerHTML={{ __html: place.teaser }}></p>
                </Text>
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionToggle title="Information" />
                    <AccordionPanel pt="4" pb="4" maxH="60vh" overflow="scroll">
                      <div
                        className="information-text"
                        dangerouslySetInnerHTML={{ __html: place.information }}
                      />
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionToggle title="Interview" />
                    <AccordionPanel pt="4" pb="4">
                      <AudioPlayer
                        headline={place.interview.title}
                        url={`${withPrefix('/assets/interviews/')}${
                          place.interview.url
                        }`}
                        setPlayingState={setIsPlaying}
                        play={isPlaying}
                      />
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionToggle title="Quiz" />
                    <AccordionPanel pt="4" pb="4">
                      <Quiz id={place.quiz.id} />
                    </AccordionPanel>
                  </AccordionItem>

                  {questions.length > 0 && (
                    <AccordionItem>
                      <AccordionToggle title="Diskussionsimpuls" />
                      <AccordionPanel pt="4" pb="4">
                        <Discussion questions={questions} />
                      </AccordionPanel>
                    </AccordionItem>
                  )}

                  <AccordionItem>
                    <AccordionToggle title="Glossar" />
                    <AccordionPanel pt="4" pb="4">
                      <Glossary entries={place.glossary.map((e) => e.entry)} />
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionToggle title="Literatur- und Bildhinweise" />
                    <AccordionPanel pb={4}>
                      <References
                        headline={`Literaturhinweis${
                          place.references.length > 1 ? 'e' : ''
                        }`}
                        references={place.references.map((r) => r.reference)}
                      />

                      {place.gallery.length > 0 && (
                        <PictureCredits
                          headline={`Bildnachweis${
                            place.gallery.length > 1 ? 'e' : ''
                          }`}
                          credits={place.gallery.map((g) => g.photo.credit)}
                        />
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
              <Box
                w={['0', '20%', '30%']}
                p="5"
                display={['none', 'none', 'block']}
              >
                <Box display="flex">
                  {previousPlace && (
                    <Button
                      as={Link}
                      to={withPrefix(`place${previousPlace.fields.slug}`)}
                      colorScheme="blue"
                      variant="outline"
                      title={previousPlace.frontmatter.title}
                    >
                      <Icon as={BsChevronLeft} color="blue.500" />
                    </Button>
                  )}
                  <Button
                    as={Link}
                    to={withPrefix(`/`)}
                    flex="1"
                    mr="1"
                    ml="1"
                    colorScheme="blue"
                    variant="outline"
                    title="Übersichtskarte"
                  >
                    <Icon as={GrMap} color="blue.500" />
                  </Button>
                  {nextPlace && (
                    <Button
                      as={Link}
                      to={withPrefix(`place${nextPlace.fields.slug}`)}
                      colorScheme="blue"
                      variant="outline"
                      title={nextPlace.frontmatter.title}
                    >
                      <BsChevronRight />
                    </Button>
                  )}
                </Box>

                <Divider mt="5" mb="5" />
                <Button
                  leftIcon={isPlaying ? <FiPauseCircle /> : <FiPlayCircle />}
                  colorScheme="blue"
                  variant="outline"
                  mb="4"
                  w="100%"
                  onClick={() => {
                    setIsPlaying(!isPlaying)
                  }}
                >
                  Interview {isPlaying ? 'pausieren' : 'anhören'}
                </Button>

                {place.gallery.map((item, index) => (
                  <Box
                    key={item.photo.image.id}
                    mb="5"
                    onClick={() => {
                      setBigPictureIndex(index)
                    }}
                  >
                    <GatsbyImage
                      image={getImage(item.photo.image.cropped.gatsbyImageData)}
                      alt={item.photo.description}
                      style={{ marginBottom: '5px', cursor: 'zoom-in' }}
                    />
                    <Box fontSize="sm" color="gray.600">
                      <Text as="span" mr="1">
                        Abb. {index + 1}:
                      </Text>
                      <Text as="span" fontWeight="light">
                        {item.photo.description}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Flex>
          </Container>
        </Box>
      </>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PlacePage($id: String!) {
    all: allMarkdownRemark {
      nodes {
        id
      }
      edges {
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }

    place: markdownRemark(id: { eq: $id }) {
      id
      information: html
      fields {
        slug
      }
      frontmatter {
        title
        subTitle
        teaser
        places {
          location
          address
          title
        }
        discussion {
          question {
            html
          }
        }
        glossary {
          entry {
            term
            explanation
          }
        }
        gallery {
          photo {
            description
            image {
              id
              cropped: childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  width: 250
                  height: 250
                  transformOptions: { fit: COVER, cropFocus: NORTH }
                )
              }
              big: childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  width: 800
                  quality: 80
                )
              }
            }
            credit
          }
        }
        interview {
          title
          url
        }
        quiz {
          id
          teaser
        }
        author
        references {
          reference
        }
      }
    }
  }
`
