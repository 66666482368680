import * as React from 'react'

import * as L from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'

import { MdZoomOutMap } from 'react-icons/md'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import '../map.css'

import { Heading, Button, Icon } from '@chakra-ui/react'
import { LatLngBounds } from 'leaflet'

if (typeof window !== 'undefined') {
  // @ts-ignore
  delete L.Icon.Default.prototype._getIconUrl
  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

  L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
  })
}

/* eslint-disable-next-line */
export interface Place {
  title: string
  description: string
  coordinates: [number, number]
  url: string
}
export interface PlacesMapProps {
  places: Place[]
  clickHandler: (url: string) => void
}

const ResetButton = ({ bounds }: { bounds: LatLngBounds }) => {
  const map = useMap()

  return (
    <Button
      colorScheme="blue"
      position="absolute"
      top="13px"
      right="72px"
      zIndex="99999"
      onClick={() => map.fitBounds(bounds)}
    >
      <Icon as={MdZoomOutMap} />
    </Button>
  )
}

export function PlacesMap(props: PlacesMapProps) {
  if (typeof window === 'undefined') {
    return null
  }

  const bounds = L.latLngBounds(
    props.places.map((p) => [p.coordinates[1], p.coordinates[0]])
  ).pad(1)

  return (
    <MapContainer
      zoom={7}
      bounds={bounds}
      scrollWheelZoom={false}
      gestureHandling={true}
      gestureHandlingText={{
        touch: 'Zwei-Finger Geste zum zoomen',
        scroll: 'Ctrl + Scroll zum zoomen',
        scrollMac: '\u2318 + scroll zum zoomen',
      }}
    >
      <ResetButton bounds={bounds} />
      <TileLayer
        attribution="Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL."
        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup>
        {props.places.map((place) => (
          <Marker
            key={place.url}
            position={[place.coordinates[1], place.coordinates[0]]}
            riseOnHover={true}
            eventHandlers={{
              click: (e) => {
                props.clickHandler(place.url)
              },
            }}
          >
            <Tooltip direction="top" offset={[-16, -11]}>
              <Heading size="xs">{place.title}</Heading>
              <p>{place.description}</p>
            </Tooltip>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  )
}

export default PlacesMap
