import * as React from 'react'
import { Box, Heading, ListItem, OrderedList } from '@chakra-ui/react'

/* eslint-disable-next-line */
export interface DiscussionProps {
  questions: string[]
}

export function Discussion(props: DiscussionProps) {
  return (
    <Box>
      {props.questions.length > 1 && (
        <OrderedList spacing={3}>
          {props.questions.map((question, index) => (
            <ListItem key={index}>
              <div
                dangerouslySetInnerHTML={{
                  __html: question,
                }}
              />
            </ListItem>
          ))}
        </OrderedList>
      )}
      {props.questions.length === 1 && (
        <div
          dangerouslySetInnerHTML={{
            __html: props.questions[0],
          }}
        />
      )}
    </Box>
  )
}

export default Discussion
