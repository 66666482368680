import * as React from 'react'
import { Box, Container, Image, Link } from '@chakra-ui/react'

import BMILogo from '../../static/assets/logos/BMI.svg'
import JLIDLogo from '../../static/assets/logos/2021jlid.svg'
import { Link as GatsbyLink } from 'gatsby'
/* eslint-disable-next-line */
export interface FooterProps {}

const MetaLink = ({ to, title }) => (
  <Link as={GatsbyLink} to={to} fontSize="xs" color="gray.800">
    {title}
  </Link>
)

export function Footer(props: FooterProps) {
  return (
    <Box as="footer" h="auto">
      <Container
        maxW="container.xl"
        display="flex"
        pt="15px"
        flexDir={['column', 'row']}
        alignItems={['center']}
      >
        <Box display="flex" flexDir="row" alignItems="center">
          <Image src={BMILogo} alt="BMI" position="relative" top="-3px" />
          <Image src={JLIDLogo} alt="2021" h="30" ml="5" />
        </Box>
        <Box ml={['0', 'auto']} mt="5">
          <nav>
            <ul>
              <li>
                <MetaLink to="/datenschutz" title="Datenschutz" />
              </li>
              <li>
                <MetaLink to="/impressum" title="Impressum" />
              </li>
            </ul>
          </nav>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
