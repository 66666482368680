import { SlideFade } from '@chakra-ui/react'

import { Box, Container, Heading, Image } from '@chakra-ui/react'
import { Link } from 'gatsby'
import { withPrefix } from 'gatsby-link'
import * as React from 'react'
import LMULogo from '../../static/assets/logos/LMU.svg'
/* eslint-disable-next-line */
export interface HeaderProps {}

export function Header(props: HeaderProps) {
  return (
    <Box as="header" h="88px">
      <Container
        h="100%"
        minH="88px"
        maxW="container.xl"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box>
          <Link to={withPrefix('/')}>
            <SlideFade in={true} delay={0.1}>
              <Heading
                as="h2"
                textTransform="uppercase"
                letterSpacing="0.5px"
                fontWeight="light"
                mb="-0.5"
                color="gray.600"
                fontSize={['xxs', 'xs', 'xs']}
              >
                Interaktive Karte zur deutsch-jüdischen Geschichte
              </Heading>
            </SlideFade>

            <Heading
              as="h1"
              fontWeight="extrabold"
              letterSpacing="-1px"
              ml="-1"
              fontSize={['md', '2xl', '3xl']}
            >
              Common Places, Common Times
            </Heading>
          </Link>
        </Box>

        <Box ml="auto" display="flex" flexDir="row">
          <Box
            fontSize="xs"
            w="200px"
            mr="4"
            color="gray.700"
            display={['none', 'none', 'block']}
          >
            Ein Projekt des Lehrstuhls für Jüdische Geschichte und Kultur
          </Box>
          <Image
            src={LMULogo}
            alt="Lehrstuhls für Jüdische Geschichte und Kultur der Ludwig-Maximilians-Universität München"
            h="40px"
          />
        </Box>
      </Container>
    </Box>
  )
}

export default Header
